import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import keycloak from "../../keycloak";

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/
const initialState = {
  token: "",
  tokenData: null,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload.token;
      state.tokenData = jwtDecode(action.payload.token);
    },
    logout() {
      keycloak.logout();
    },
  },
  extraReducers: {},
});

export const { setToken, logout } = auth.actions;
export default auth.reducer;
