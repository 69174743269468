import React from "react";
import moment from "moment";
import { groupBy, toPairs, pathOr } from "ramda";
import { useTranslation } from "react-i18next";

import { Timeline, Typography, Breadcrumb, Empty } from "antd";

const TLItem = (ev) => {
  const isSuccess = pathOr(false, ["response", "success"], ev);
  const message = pathOr(null, ["response", "message"], ev);
  const data = pathOr(null, ["response", "message", "Data"], ev);

  return (
    <Timeline.Item color={ev.response.success ? undefined : "red"}>
      <Typography.Text strong>
        {moment(ev.createdAt).format("HH:mm:ss")}
      </Typography.Text>
      <Breadcrumb>
        <Breadcrumb.Item>{ev.commandType}</Breadcrumb.Item>
        <Breadcrumb.Item>{ev.resourceType}</Breadcrumb.Item>
        <Breadcrumb.Item>{ev.commandName}</Breadcrumb.Item>
      </Breadcrumb>
      <p>
        {toPairs(ev.commandInfo).map(([prop, value], idx) => (
          <span key={idx}>
            {prop}: {value}
            <br />
          </span>
        ))}
      </p>
      {isSuccess ? (
        <Typography.Text type="primary">
          {!!data && typeof data === "object" ? JSON.stringify(data) : data}
        </Typography.Text>
      ) : (
        <Typography.Text type="danger">
          {!!message && typeof message === "object"
            ? JSON.stringify(message)
            : message}
        </Typography.Text>
      )}
    </Timeline.Item>
  );
};

const CommandHistory = (data) => {
  const { t } = useTranslation();

  if (!data || !data.items || !data.items.length) {
    return <Empty />;
  }

  const groupedData = groupBy(
    (ev) => moment(ev.createdAt).format("YYYY-MM-DD"),
    data.items
  );

  return (
    <>
      {data.hasNextPage && (
        <p>
          {t("hitorySize", {
            totalItems: data.totalItems,
            limit: data.limit,
          })}
        </p>
      )}
      {toPairs(groupedData).map(([date, events]) => {
        return (
          <>
            <Typography.Title level={3}>
              {moment(date).format("dddd DD MMM")}
            </Typography.Title>
            <br />
            <Timeline>
              {events.map((ev, idx) => (
                <TLItem key={idx} {...ev} />
              ))}
            </Timeline>
          </>
        );
      })}
    </>
  );
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <span>
      {t("history")}: <span className="txtPrimary">{t("commands")}</span>
    </span>
  );
};
const Help = () => {
  const { t } = useTranslation();
  return <p className="helpText">{t("helps.history.command")}</p>;
};

CommandHistory.Title = Title;
CommandHistory.Help = Help;
CommandHistory.searchable = true;
export default CommandHistory;
