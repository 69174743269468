import React from "react";
import moment from "moment";
import { cond, equals, always } from "ramda";
import { useTranslation } from "react-i18next";

import { Drawer, DatePicker, PageHeader, Form, Spin, Button } from "antd";

import { FilterOutlined } from "@ant-design/icons";

import styles from "../../Site.module.less";

import CommandHistory from "./_CommandHistory";
import OccupancyHistory from "./_OccupancyHistory";
import WebhookHistory from "./_WebhookHistory";
import CountingHistory from "./_CountingHistory";
import CountingHookHistory from "./_CountingHookHistory";
import WPOccupancyHistory from "./_WPOccupancyHistory";
import WPWebhookHistory from "./_WPWebhookHistory";

export const DRAWER_TYPES = {
  CLMF: "CLMF",
  COMMANDS: "COMMANDS",
  WEBHOOKS: "WEBHOOKS",
  COUNT: "COUNT",
  COUNT_WEBHOOKS: "COUNT_WEBHOOKS",
  WP_OCCUPANCY: "WP_OCCUPANCY",
  WP_WEBHOOKS: "WP_WEBHOOKS",
};

/*
|--------------------------------------------------------------------------
| Forms
|--------------------------------------------------------------------------
*/

const CriteriasForm = ({ disabled, onFinish }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleFinish = (values) => {
    return onFinish({
      startDate: values.range[0].toISOString(),
      endDate: values.range[1].toISOString(),
    });
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      <Form.Item
        name="range"
        label={t("timeRange")}
        initialValue={[
          moment().set({ hours: 0, minutes: 0 }),
          moment().set({ hours: 23, minutes: 59 }),
        ]}
      >
        <DatePicker.RangePicker
          showTime
          format="DD/MM/YYYY HH:mm"
          disabled={disabled}
        />
      </Form.Item>
      <Button block htmlType="submit" disabled={disabled}>
        {t("apply")}
      </Button>
    </Form>
  );
};

/*
|--------------------------------------------------------------------------
| Timeline
|--------------------------------------------------------------------------
*/

const BODY_STYLE = {
  padding: "40px 60px",
};
const HistoryDrawer = ({
  loading,
  type,
  data,
  visible,
  onClose,
  onUpdateCriterias,
}) => {
  const { t } = useTranslation();

  if (!type) return null;

  const History = cond([
    [equals(DRAWER_TYPES.CLMF), always(OccupancyHistory)],
    [equals(DRAWER_TYPES.COMMANDS), always(CommandHistory)],
    [equals(DRAWER_TYPES.WEBHOOKS), always(WebhookHistory)],
    [equals(DRAWER_TYPES.COUNT), always(CountingHistory)],
    [equals(DRAWER_TYPES.COUNT_WEBHOOKS), always(CountingHookHistory)],
    [equals(DRAWER_TYPES.WP_OCCUPANCY), always(WPOccupancyHistory)],
    [equals(DRAWER_TYPES.WP_WEBHOOKS), always(WPWebhookHistory)],
  ])(type);

  return (
    <Drawer
      mask
      destroyOnClose
      visible={visible}
      width={500}
      title={<History.Title />}
      onClose={onClose}
      bodyStyle={BODY_STYLE}
    >
      <History.Help />

      {History.searchable && (
        <PageHeader
          className={styles.historyDrawerHeader}
          title={t("filter")}
          extra={<FilterOutlined />}
        >
          <CriteriasForm disabled={loading} onFinish={onUpdateCriterias} />
        </PageHeader>
      )}

      {loading ? <Spin /> : <History {...data} />}
    </Drawer>
  );
};

export default HistoryDrawer;
