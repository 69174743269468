import React from "react";
import moment from "moment";
import { groupBy, toPairs } from "ramda";
import { useTranslation } from "react-i18next";

import { Timeline, Typography, Empty, Tag } from "antd";

import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const TlItem = (ev) => {
  const { t } = useTranslation();

  return (
    <Timeline.Item
      color={ev.response ? (ev.response.success ? undefined : "red") : "gray"}
    >
      <Typography.Text strong>
        {moment(ev.createdAt).format("HH:mm:ss")}
      </Typography.Text>
      <div>
        {!!ev.response &&
          (!ev.response.success ? (
            <Tag icon={<CloseCircleOutlined />} color="error">
              {ev.response.status}
            </Tag>
          ) : (
            <Tag icon={<CheckCircleOutlined />} color="success">
              {ev.response.status}
            </Tag>
          ))}
      </div>
      <p>{ev.url}</p>
      <ul>
        {ev.updated.map((up) => (
          <li>
            <strong>{up.reference}</strong> {t("value")}:{" "}
            {up.normalizedOccupancyStatus} ({up.occupancyStatus})
          </li>
        ))}
      </ul>
      {!!ev.response && !ev.response.success && (
        <Typography.Text type="danger">
          {typeof ev.response.message === "object"
            ? JSON.stringify(ev.response.message)
            : ev.response.message}
        </Typography.Text>
      )}
    </Timeline.Item>
  );
};

const WPWebhookHistory = (data) => {
  const { t } = useTranslation();

  if (!data || !data.items || !data.items.length) {
    return <Empty />;
  }

  const groupedData = groupBy(
    (ev) => moment(ev.createdAt).format("YYYY-MM-DD"),
    data.items
  );

  return (
    <>
      {data.hasNextPage && (
        <p>
          {t("hitorySize", {
            totalItems: data.totalItems,
            limit: data.limit,
          })}
        </p>
      )}
      {toPairs(groupedData).map(([date, events]) => {
        return (
          <>
            <Typography.Title level={3}>
              {moment(date).format("dddd DD MMM")}
            </Typography.Title>
            <br />
            <Timeline>
              {events.map((ev, idx) => (
                <TlItem key={idx} {...ev} />
              ))}
            </Timeline>
          </>
        );
      })}
    </>
  );
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <span>
      {t("history")}: <span className="txtPrimary">{t("webhooks")}</span>
    </span>
  );
};
const Help = () => {
  const { t } = useTranslation();
  return <p className="helpText">{t("helps.history.webhook")}</p>;
};

WPWebhookHistory.Title = Title;
WPWebhookHistory.Help = Help;
WPWebhookHistory.searchable = true;
export default WPWebhookHistory;
