export default {
  translation: {
    nav: {
      site: "Configuration",
      rooms: "Equipements",
    },
    login: "Login",
    logout: "Logout",
    save: "Save",
    building: "Building",
    roomType: "Room type",
    documentation: "API Documentation",
    loadPivot: "Load a new Pivot file",
    search: "Search",
    filter: "Filter",
    apply: "Apply",
    timeRange: "Period",
    value: "Value",
    history: "History",
    webhooks: "Webhooks",
    commands: "Commands",
    workplaces: "Workplaces",
    webhooksHistory: "Webhooks history",
    commandsHistory: "Commands history",
    occupancyHistory: "Occupancy history",
    hitorySize: "{{totalItems}} results are available. {{limit}} first has been retrieved.",
    noEquipements: "No room sensor available",
    noWorkplaces: "This room has no workplace",
    equipements: {
      occupancy: "Occupancy",
      fanSpeed: "Air-conditioning power",
      fanOffset: "Temperature",
      fan: "Air-conditioning",
      light: "Light",
      blind: "Blind shutters",
      counting: "Attendance",
      pm1: "Particules < 1μm",
      pm25: "Particules < 2.5μm",
      pm4: "Particules < 4μm",
      pm10: "Particules < 10μm",
    },
    helps: {
      login:
        "If you have a simulator or monitor access, enter you email address to get your login links",
      simulator:
        'This site is currently in <b>"simulator mode"</b>. Equipement\'s data are fake. This tool allows you to consult the currently simulated state, to update this state and to accelerate history',
      monitor:
        "This site is currently in <b>\"production mode\"</b>. Equipement's data are real. This tool allows you to consult the equipement's states and histories",
      apikey:
        "The following informations allows you to use the simulator or monitor API for your tests",
      search:
        "Search for a room by its name or its reference (displayName and reference column from the Pivot file)",
      display_name: 'Pivot\'s "DisplayName" column',
      reference: 'Pivot\'s "Reference" column',
      building: 'Pivot\'s "Building" column',
      roomType: 'Pivot\'s "Customer type of room" column',
      bookable: "Bookable",
      history: {
        equipement: "Equipement state change history",
        webhook: "Webhooks call history with response status codes",
        command: "History of commands sent to the equipement",
        workplaces: "History of workplaces occupancy changes",
        counting: "History of people count in the room",
      },
      pivotForm:
        "Use this form to upload a new Pivot file and update the referential of your simulator site. This process can take some time depending on your file size",
    },
    forms: {
      noSite: "Start by choosing a site",
      pivotFormTitle: "Referential update",
      email: "Email address",
      email_required: "You must enter your email address",
      pivot_helper: "Clic or drag n drop your Pivot file",
      pivot_helper_format: 'File with ".xlsx". or ".xlsm" format',
      pivot_required: "You must add a file",
      randomMode_label: "Activate random mode",
    },
    loginSuccess: "A mail containing your login links have been sent to you",
    loginFailure: "Login failure. Check your credential",
    updateConfFailure: "Unable to update your site configuration",
    updatePivotSuccess: "You site referential has been updated",
    updatePivotFailure: "Unable to update the site referential",
  },
};
