import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import cx from "clsx";

import { Input } from "antd";

import { selectRoom } from "../../../redux/services/SiteSlice";

import styles from "../Site.module.less";

const RoomList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const menu = useRef(null);

  const [searchInput, setSearchInput] = useState("");

  const rooms = useSelector((state) => state.site.rooms);
  const currentRoom = useSelector((state) => state.site.currentRoom);

  const [height, setHeight] = useState(0);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const onSelectRoom = (room) => () => {
    const ref = room.reference;
    dispatch(selectRoom(ref));
  };

  const displayedRooms = rooms.filter(
    (r) =>
      new RegExp(searchInput, "i").test(r.display_name) ||
      new RegExp(searchInput, "i").test(r.reference)
  );

  const renderMenuItem = ({ index, style }) => {
    const r = displayedRooms[index];

    return (
      <div
        key={r.reference}
        className={cx("ant-menu-item", styles.roomMenuItem, {
          "ant-menu-item-selected": r.reference === currentRoom,
        })}
        style={style}
        onClick={onSelectRoom(r)}
      >
        {r.display_name}
        <p>{r.reference}</p>
        <p>
          {(r.workPlaces || []).length} {t("workplaces")}
        </p>
      </div>
    );
  };

  // console.log(
  //   menu,
  //   menu && menu.current,
  //   menu && menu.current && menu.current.offsetHeight
  // );

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div className={styles.searchFieldWrapper}>
        <p className="helpText-small">{t("helps.search")}</p>
        <Input
          placeholder={t("search") + "..."}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <div ref={measuredRef} style={{ flexGrow: 1 }}>
        {height && (
          <List
            className="ant-menu ant-menu-light ant-menu-root ant-menu-inline"
            height={height}
            itemCount={displayedRooms.length}
            itemSize={80}
          >
            {renderMenuItem}
          </List>
        )}
      </div>
    </div>
  );
};

export default RoomList;
