import { DownOutlined, ExportOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Cascader,
  Col,
  Form,
  Layout,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { path } from "ramda";
import React, { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LogoSchneider from "../assets/img/logo.png";
import Logo from "../assets/img/thumbnail.png";
import useAuth from "../providers/AuthProvider";
import { logout } from "../redux/services/AuthSlice";
import {
  getSiteConf,
  listApps,
  listRooms,
  selectSite,
  selecteApp,
  selecteAppAndSite,
  selectedApp,
  selectedSite,
} from "../redux/services/SiteSlice";
import Rooms from "../views/Site/Rooms/Rooms";
import styles from "./Layouts.module.less";

const formVerticalLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

const InitialSelect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const apps = useSelector((state) => state.site.apps);
  const app = useSelector(selectedApp);

  useEffect(() => {
    dispatch(listApps());
  }, [dispatch]);

  return (
    <Layout style={{ alignItems: "center", justifyContent: "center" }}>
      <div style={{ width: 500, display: "flex", flexDirection: "column" }}>
        <Typography.Title level={3}>{t("forms.noSite")}</Typography.Title>
        <Card style={{ boxShadow: "0px 4px 10px 0px rgba(0,0,0,.15)" }}>
          <Form.Item {...formVerticalLayout} label={"Application"}>
            <Select
              showSearch
              optionFilterProp="label"
              options={apps
                .map((i) => ({ label: i.label, value: i.id }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              onChange={(id) => {
                dispatch(selecteApp(id));
              }}
            />
          </Form.Item>
          {!!app && (
            <Form.Item {...formVerticalLayout} label={"Site"}>
              <Select
                showSearch
                optionFilterProp="label"
                options={app.sites
                  .map((i) => ({ label: i.name, value: i.id }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(id) => {
                  dispatch(selectSite(id));
                }}
              />
            </Form.Item>
          )}
        </Card>
      </div>
    </Layout>
  );
};

const SiteSelector = ({ children }) => {
  const dispatch = useDispatch();

  const apps = useSelector((state) => state.site.apps);
  const app = useSelector(selectedApp);
  const site = useSelector(selectedSite);

  const options = useMemo(() => {
    return apps
      .map((i) => ({
        label: i.label,
        value: i.id,
        isLeaf: false,
        children: i.sites
          .map((i) => ({ label: i.name, value: i.id }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [apps]);

  return (
    <Cascader
      value={[app?.id, site?.id]}
      options={options}
      onChange={([appId, siteId]) => {
        dispatch(selecteAppAndSite({ appId, siteId }));
      }}
    >
      {children}
    </Cascader>
  );
};

const MainLayout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useAuth();

  const language = i18n.language.split("-")[0];

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const app = useSelector(selectedApp);
  const site = useSelector(selectedSite);
  const siteConf = useSelector((state) => state.site.selectedSiteConf);
  const siteId = site?.id;

  useEffect(() => {
    if (siteId) {
      dispatch(getSiteConf(siteId));
      dispatch(listRooms({ siteId: siteId, locale: language }));
    }
  }, [dispatch, siteId, language]);

  if (!site)
    return (
      <Layout className={styles.mainContainer}>
        <Layout.Content>
          <InitialSelect />
        </Layout.Content>
      </Layout>
    );
  return (
    <Layout className={styles.mainContainer}>
      <Layout.Header className={styles.brandHeader}>
        <div className={styles.brandWPE}>
          <img src={Logo} alt="" />
          <div>WPE Monitor</div>
        </div>

        <SiteSelector>
          <Button
            shape="round"
            type="primary"
            style={{ fontWeight: "bold", paddingLeft: 20, paddingRight: 20 }}
          >
            <Space>
              {app.label}/{site.name}
              <DownOutlined />
            </Space>
          </Button>
        </SiteSelector>

        <div className="grow" />

        <Button onClick={() => setIsModalOpen(true)}>
          <Space>
            Configuration
            <ExportOutlined />
            {/* <Tag color="green" style={{ margin: 0 }}>
              {siteConf?.isSim ? "Simulateur" : "Production"}
            </Tag> */}
          </Space>
        </Button>

        <Popover
          placement="bottomLeft"
          trigger="click"
          content={
            <Space>
              {userInfo?.email}
              <Button type="link" icon={<LogoutOutlined />} onClick={() => dispatch(logout())} />
            </Space>
          }
        >
          <Avatar style={{ marginRight: 0, marginLeft: 12, cursor: "pointer" }}>
            {userInfo?.email.split("@")[0].split(".")[0].charAt(0).toUpperCase()}
          </Avatar>
        </Popover>

        <img src={LogoSchneider} className="App-Logo" alt="" style={{ marginLeft: 10 }} />
      </Layout.Header>

      {/* <Layout.Header className={styles.mainHeader}>
        <h1 className={styles.mainTitle}>{!!site && site.site}</h1>
        <Menu className={styles.mainMenu} mode="horizontal" selectedKeys={[location.pathname]}>
          <Menu.Item key="/site" onClick={() => history.push("/site")}>
            {t("nav.site")}
          </Menu.Item>
          <Menu.Item key="/rooms" onClick={() => history.push("/rooms")}>
            {t("nav.rooms")}
          </Menu.Item>
        </Menu>
      </Layout.Header> */}
      <Layout.Content>
        <Rooms />

        <Modal
          open={isModalOpen}
          footer={<Button onClick={() => setIsModalOpen(false)}>Fermer</Button>}
        >
          <Typography.Title level={3}>Configuration</Typography.Title>
          <Typography.Paragraph>
            {!!siteConf && siteConf.isSim ? (
              <Trans i18nKey="helps.simulator" components={{ b: <Typography.Text strong /> }} />
            ) : (
              <Trans i18nKey="helps.monitor" components={{ b: <Typography.Text strong /> }} />
            )}
          </Typography.Paragraph>
          <Typography.Paragraph>{t("helps.apikey")}</Typography.Paragraph>
          <br />
          <Row gutter={[10, 10]}>
            <Col span={8}>Site ID</Col>
            <Col style={{ textAlign: "right" }} span={16}>
              <Typography.Text copyable strong>
                {site?.id}
              </Typography.Text>
            </Col>
            <Col span={8}>ApiKey</Col>
            <Col style={{ textAlign: "right" }} span={16}>
              <Typography.Text copyable strong>
                {path(["proxy", "apiKey"], siteConf)}
              </Typography.Text>
            </Col>
            <Col span={8}>SecretKey</Col>
            <Col style={{ textAlign: "right" }} span={16}>
              <Typography.Text copyable strong>
                {path(["proxy", "secretKey"], siteConf)}
              </Typography.Text>
            </Col>
            {path(["proxy", "baseUrl"], siteConf) && (
              <>
                <Col span={8}>URL</Col>
                <Col style={{ textAlign: "right" }} span={16}>
                  <Typography.Text copyable strong>
                    {path(["proxy", "baseUrl"], siteConf)}
                  </Typography.Text>
                </Col>
              </>
            )}
            <br />
            <br />
            {siteConf?.documentationUrl && (
              <Button block type="primary">
                <a href={siteConf.documentationUrl} target="_blank" rel="noopener noreferrer">
                  {t("documentation")}
                </a>
              </Button>
            )}
          </Row>
        </Modal>
      </Layout.Content>
    </Layout>
  );
};

export default MainLayout;
