import { createSelector, createSlice, createAsyncThunk as thunk } from "@reduxjs/toolkit";
import PROXY from "../connectors/ProxyConnector";

const initialState = {
  apps: [],
  selectedAppId: null,
  selectedSiteId: null,
  selectedSiteConf: null,

  isFetchingRoomDetails: false,
  isFetchingRoomWorkplaces: false,
  isFetchingRooms: false,
  isLoadingSite: false,

  rooms: [],
  currentRoom: "",
  roomDetails: null,
  roomWorkplaces: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/
const EXTRA_REDUCERS = {};

export const listApps = thunk("site/listApps", async () => {
  const apps = await PROXY.listApps();
  return apps;
});
EXTRA_REDUCERS[listApps.fulfilled] = (state, action) => {
  state.apps = action.payload;
};

export const getSiteConf = thunk("site/getSiteConf", async (siteId) => {
  const details = await PROXY.getSiteConf(siteId);
  return details;
});
EXTRA_REDUCERS[getSiteConf.pending] = (state) => {
  state.isLoadingSite = true;
};
EXTRA_REDUCERS[getSiteConf.fulfilled] = (state, { payload }) => {
  state.isLoadingSite = false;
  state.selectedSiteConf = payload;
};
EXTRA_REDUCERS[getSiteConf.rejected] = (state) => {
  state.isLoadingSite = false;
};

export const updateSiteConf = thunk(
  "site/updateSiteConf",
  async ({ siteId, conf }, { dispatch }) => {
    try {
      await PROXY.updateSiteConf(siteId, conf);
    } finally {
      dispatch(getSiteConf(siteId));
    }
  }
);

export const listRooms = thunk("site/listRooms", async ({ siteId, locale }) => {
  const rooms = await PROXY.listSiteSpaces(siteId, { locale });
  return rooms;
});
EXTRA_REDUCERS[listRooms.pending] = (state) => {
  state.isFetchingRooms = true;
};
EXTRA_REDUCERS[listRooms.fulfilled] = (state, { payload }) => {
  state.isFetchingRooms = false;
  state.rooms = payload;
};
EXTRA_REDUCERS[listRooms.rejected] = (state) => {
  state.isFetchingRooms = false;
};

export const getRoomDetails = thunk("site/roomDetails", async ({ siteId, roomRef, locale }) => {
  const details = await PROXY.getSpaceDetails(siteId, roomRef, { locale });
  return details;
});
EXTRA_REDUCERS[getRoomDetails.pending] = (state) => {
  state.isFetchingRoomDetails = true;
};
EXTRA_REDUCERS[getRoomDetails.fulfilled] = (state, { payload }) => {
  state.isFetchingRoomDetails = false;
  state.roomDetails = payload;
};
EXTRA_REDUCERS[getRoomDetails.rejected] = (state) => {
  state.isFetchingRoomDetails = false;
};

export const getRoomWorkplaces = thunk(
  "site/roomWorkplaces",
  async ({ siteId, roomRef, locale }) => {
    const workplaces = await PROXY.listWorkplaces(siteId, roomRef, { locale });
    return workplaces;
  }
);
EXTRA_REDUCERS[getRoomWorkplaces.pending] = (state) => {
  state.isFetchingRoomWorkplaces = true;
};
EXTRA_REDUCERS[getRoomWorkplaces.fulfilled] = (state, { payload }) => {
  state.isFetchingRoomWorkplaces = false;
  state.roomWorkplaces = payload;
};
EXTRA_REDUCERS[getRoomWorkplaces.rejected] = (state) => {
  state.isFetchingRoomWorkplaces = false;
};

export const updateRoomSensors = thunk(
  "site/updateRoomSensors",
  async ({ siteId, roomRef, sensors }, { dispatch }) => {
    try {
      await PROXY.updateSpace(siteId, roomRef, sensors);
    } finally {
      dispatch(getRoomDetails({ siteId, roomRef }));
      dispatch(getRoomWorkplaces({ siteId, roomRef }));
    }
  }
);
export const updateWorkplace = thunk(
  "site/updateWorkplace",
  async ({ siteId, roomRef, workplaceRef, data }, { dispatch }) => {
    try {
      await PROXY.updateWorkplace(siteId, roomRef, workplaceRef, data);
    } finally {
      dispatch(getRoomDetails({ siteId, roomRef }));
      dispatch(getRoomWorkplaces({ siteId, roomRef }));
    }
  }
);

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/
const site = createSlice({
  name: "site",
  initialState,
  reducers: {
    selecteApp: (state, action) => {
      state.selectedAppId = action.payload;
      state.rooms = initialState.rooms;
      state.currentRoom = initialState.currentRoom;
      state.roomDetails = initialState.roomDetails;
      state.roomWorkplaces = initialState.roomWorkplaces;
    },
    selectSite: (state, action) => {
      state.selectedSiteId = action.payload;
      state.rooms = initialState.rooms;
      state.currentRoom = initialState.currentRoom;
      state.roomDetails = initialState.roomDetails;
      state.roomWorkplaces = initialState.roomWorkplaces;
    },
    selecteAppAndSite: (state, action) => {
      state.selectedAppId = action.payload.appId;
      state.selectedSiteId = action.payload.siteId;
      state.rooms = initialState.rooms;
      state.currentRoom = initialState.currentRoom;
      state.roomDetails = initialState.roomDetails;
      state.roomWorkplaces = initialState.roomWorkplaces;
    },
    selectRoom: (state, { payload: roomRef }) => {
      state.currentRoom = roomRef;
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

/*
|--------------------------------------------------------------------------
| Selectors
|--------------------------------------------------------------------------
*/
export const selectedApp = createSelector(
  (state) => state.site.apps,
  (state) => state.site.selectedAppId,
  (apps, selectedAppId) => apps.find((app) => app.id === selectedAppId)
);
export const selectedSite = createSelector(
  (state) => state.site.apps,
  (state) => state.site.selectedAppId,
  (state) => state.site.selectedSiteId,
  (apps, selectedAppId, selectedSiteId) => {
    const app = apps.find((app) => app.id === selectedAppId);
    return app?.sites.find((site) => site.id === selectedSiteId);
  }
);

export const { selecteApp, selectSite, selecteAppAndSite, selectRoom } = site.actions;
export default site.reducer;
