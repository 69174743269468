import React from "react";

import { Layout } from "antd";
import RoomList from "./RoomList";
import RoomDetails from "./RoomDetails";

import styles from "../Site.module.less";

const SIDER_LENGTH = 300;

const Rooms = () => {
  return (
    <Layout>
      <Layout.Sider className={styles.sider} theme="light" width={SIDER_LENGTH}>
        <RoomList />
      </Layout.Sider>

      <Layout style={{ marginLeft: SIDER_LENGTH }}>
        <Layout.Content className={styles.container}>
          <RoomDetails />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default Rooms;
