import { Button, Layout, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import keycloak from "../../keycloak";
import styles from "./Login.module.less";

const Login = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.Content className={styles.loginContainer}>
        <div className={styles.loginFormWrapper}>
          <Typography.Title level={3}>Schneider Monitor</Typography.Title>
          <Button block size="large" shape="round" type="primary" onClick={keycloak.login}>
            {t("login")}
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Login;
