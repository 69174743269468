import React from "react";
import moment from "moment";
import { groupBy, toPairs } from "ramda";
import { useTranslation } from "react-i18next";

import { Timeline, Typography, Empty } from "antd";

const TlItem = (ev) => {
  return (
    <Timeline.Item>
      <Typography.Text strong>
        {moment(ev.updatedAt).format("HH:mm:ss")}
      </Typography.Text>
      <div>Count: {ev.count}</div>
    </Timeline.Item>
  );
};

const CountingHistory = (data) => {
  const { t } = useTranslation();

  if (!data || !data.items || !data.items.length) {
    return <Empty />;
  }

  const groupedData = groupBy(
    (ev) => moment(ev.updatedAt).format("YYYY-MM-DD"),
    data.items
  );

  return (
    <>
      {data.hasNextPage && (
        <p>
          {t("hitorySize", {
            totalItems: data.totalItems,
            limit: data.limit,
          })}
        </p>
      )}
      {toPairs(groupedData).map(([date, events]) => {
        return (
          <>
            <Typography.Title level={3}>
              {moment(date).format("dddd DD MMM")}
            </Typography.Title>
            <br />
            <Timeline>
              {events.map((ev, idx) => (
                <TlItem key={idx} {...ev} />
              ))}
            </Timeline>
          </>
        );
      })}
    </>
  );
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <span>
      {t("history")}: <span className="txtPrimary">{t("counting")}</span>
    </span>
  );
};
const Help = () => {
  const { t } = useTranslation();
  return <p className="helpText">{t("helps.history.counting")}</p>;
};

CountingHistory.Title = Title;
CountingHistory.Help = Help;
CountingHistory.searchable = true;
export default CountingHistory;
