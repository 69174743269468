import Icon from "@ant-design/icons";
import { Empty, Tabs, notification } from "antd";
import moment from "moment";
import { isNil, omit, reject } from "ramda";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NoDataImg } from "../../../assets/img/undraw/undraw_no_data.svg";
import PROXY from "../../../redux/connectors/ProxyConnector";
import { logout } from "../../../redux/services/AuthSlice";
import {
  getRoomDetails,
  getRoomWorkplaces,
  selectRoom,
  selectedSite,
  updateRoomSensors,
  updateWorkplace,
} from "../../../redux/services/SiteSlice";
import Equipements from "./Equipements";
import HistoryDrawer, { DRAWER_TYPES } from "./HistoryDrawer";
import RoomHeader from "./RoomHeader";
import Workplaces from "./Workplaces";

const RoomDetails = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const lang = i18n.language.split("-")[0];

  const [showHistoryDrawer, setShowHistoryDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState(null);
  const [drawerData, setDrawerData] = useState([]);
  const [drawerLoading, setDrawerLoading] = useState(false);

  const [currentCommandResType, setCurrentCommandResType] = useState(null);

  const site = useSelector(selectedSite);
  const siteId = site?.id;
  const rooms = useSelector((state) => state.site.rooms);
  const currentRoom = useSelector((state) => state.site.currentRoom);
  const roomDetails = useSelector((state) => state.site.roomDetails);
  const workplaces = useSelector((state) => state.site.roomWorkplaces);
  const isFetchingRooms = useSelector((state) => state.site.isFetchingRooms);
  const isFetchingDetails = useSelector((state) => state.site.isFetchingRoomDetails);
  const isFetchingWorkplaces = useSelector((state) => state.site.isFetchingRoomWorkplaces);

  useEffect(() => {
    if (rooms.length > 0 && !currentRoom) dispatch(selectRoom(rooms[0].reference));
  }, [dispatch, currentRoom, rooms]);

  useEffect(() => {
    if (currentRoom) {
      dispatch(getRoomDetails({ siteId, roomRef: currentRoom, locale: lang }));
      dispatch(getRoomWorkplaces({ siteId, roomRef: currentRoom, locale: lang }));
    }
  }, [dispatch, currentRoom, siteId, lang]);

  const refreshDetails = () => {
    dispatch(getRoomDetails({ siteId, roomRef: currentRoom, locale: lang }));
    dispatch(getRoomWorkplaces({ siteId, roomRef: currentRoom, locale: lang }));
  };

  /*
  |--------------------------------------------------------------------------
  | Room handlers
  |--------------------------------------------------------------------------
  */

  const handleWebhookHistory = async (criterias = {}) => {
    setShowHistoryDrawer(true);
    setDrawerType(DRAWER_TYPES.WEBHOOKS);
    setDrawerLoading(true);

    const startDate = criterias.startDate
      ? moment(criterias.startDate)
      : moment().set({ hours: 0, minutes: 0 });
    const endDate = criterias.endDate
      ? moment(criterias.endDate)
      : moment().set({ hours: 23, minutes: 59 });

    const res = await PROXY.webhookHistories(siteId, roomDetails.reference, {
      page: 1,
      limit: 100,
      ...criterias,
      startDate: startDate.set({ seconds: 0, milliseconds: 0 }).toISOString(),
      endDate: endDate.set({ seconds: 59, milliseconds: 999 }).toISOString(),
    }).catch((error) => {
      if (error && error.code === "401") dispatch(logout());
    });

    setDrawerData({
      ...res,
      items: res.items.map((i) => ({
        ...i,
        room: i.updated.find((u) => u.spaceId === currentRoom),
      })),
    });
    setDrawerLoading(false);
  };
  const handleCommandHistory = async (criterias = {}) => {
    setShowHistoryDrawer(true);
    setDrawerType(DRAWER_TYPES.COMMANDS);
    setDrawerLoading(true);
    if (criterias.resourceType) setCurrentCommandResType(criterias.resourceType);

    const startDate = criterias.startDate
      ? moment(criterias.startDate)
      : moment().set({ hours: 0, minutes: 0 });
    const endDate = criterias.endDate
      ? moment(criterias.endDate)
      : moment().set({ hours: 23, minutes: 59 });

    const res = await PROXY.commandHistories(
      siteId,
      roomDetails.reference,
      reject(isNil, {
        page: 1,
        limit: 100,
        resourceType: currentCommandResType,
        ...criterias,
        startDate: startDate.set({ seconds: 0, milliseconds: 0 }).toISOString(),
        endDate: endDate.set({ seconds: 59, milliseconds: 999 }).toISOString(),
      })
    ).catch((error) => {
      if (error && error.code === "401") dispatch(logout());
    });

    setDrawerData(res);
    setDrawerLoading(false);
  };
  const handleHistoryClmf = async (criterias = {}) => {
    setShowHistoryDrawer(true);
    setDrawerType(DRAWER_TYPES.CLMF);
    setDrawerLoading(true);

    const startDate = criterias.startDate
      ? moment(criterias.startDate)
      : moment().set({ hours: 0, minutes: 0 });
    const endDate = criterias.endDate
      ? moment(criterias.endDate)
      : moment().set({ hours: 23, minutes: 59 });

    const res = await PROXY.getOccupancy(siteId, roomDetails.reference, {
      page: 1,
      limit: 100,
      ...criterias,
      startDate: startDate.set({ seconds: 0, milliseconds: 0 }).toISOString(),
      endDate: endDate.set({ seconds: 59, milliseconds: 999 }).toISOString(),
    }).catch((error) => {
      if (error && error.code === "401") dispatch(logout());
    });

    setDrawerData(res);
    setDrawerLoading(false);
  };
  const handleCountingHistory = async (criterias = {}) => {
    setShowHistoryDrawer(true);
    setDrawerType(DRAWER_TYPES.COUNT);
    setDrawerLoading(true);

    const startDate = criterias.startDate
      ? moment(criterias.startDate)
      : moment().set({ hours: 0, minutes: 0 });
    const endDate = criterias.endDate
      ? moment(criterias.endDate)
      : moment().set({ hours: 23, minutes: 59 });

    const res = await PROXY.getCountingHistory(siteId, roomDetails.reference, {
      page: 1,
      limit: 100,
      ...criterias,
      startDate: startDate.set({ seconds: 0, milliseconds: 0 }).toISOString(),
      endDate: endDate.set({ seconds: 59, milliseconds: 999 }).toISOString(),
    }).catch((error) => {
      if (error && error.code === "401") dispatch(logout());
    });

    setDrawerData(res);
    setDrawerLoading(false);
  };
  const handleHistoryCountingHooks = async (criterias = {}) => {
    setShowHistoryDrawer(true);
    setDrawerType(DRAWER_TYPES.COUNT_WEBHOOKS);
    setDrawerLoading(true);

    const startDate = criterias.startDate
      ? moment(criterias.startDate)
      : moment().set({ hours: 0, minutes: 0 });
    const endDate = criterias.endDate
      ? moment(criterias.endDate)
      : moment().set({ hours: 23, minutes: 59 });

    const res = await PROXY.getCountingHookHistory(siteId, roomDetails.reference, {
      page: 1,
      limit: 100,
      ...criterias,
      startDate: startDate.set({ seconds: 0, milliseconds: 0 }).toISOString(),
      endDate: endDate.set({ seconds: 59, milliseconds: 999 }).toISOString(),
    }).catch((error) => {
      if (error && error.code === "401") dispatch(logout());
    });

    setDrawerData(res);
    setDrawerLoading(false);
  };

  /*
  |--------------------------------------------------------------------------
  | Workplaces handlers
  |--------------------------------------------------------------------------
  */

  const handleWPWebhookHistory = async (criterias = {}) => {
    setShowHistoryDrawer(true);
    setDrawerType(DRAWER_TYPES.WP_WEBHOOKS);
    setDrawerLoading(true);

    const startDate = criterias.startDate
      ? moment(criterias.startDate)
      : moment().set({ hours: 0, minutes: 0 });
    const endDate = criterias.endDate
      ? moment(criterias.endDate)
      : moment().set({ hours: 23, minutes: 59 });

    const res = await PROXY.workplaceWebhookHistories(siteId, roomDetails.reference, {
      page: 1,
      limit: 100,
      ...criterias,
      startDate: startDate.set({ seconds: 0, milliseconds: 0 }).toISOString(),
      endDate: endDate.set({ seconds: 59, milliseconds: 999 }).toISOString(),
    }).catch((error) => {
      if (error && error.code === "401") dispatch(logout());
    });

    setDrawerData({
      ...res,
      items: res.items.map((i) => ({
        ...i,
        room: i.updated.find((u) => u.roomReference === currentRoom),
        updated: i.updated.filter((up) => up.roomReference === currentRoom),
      })),
    });
    setDrawerLoading(false);
  };
  const handleWPOccupancyHistory = async (criterias = {}) => {
    setShowHistoryDrawer(true);
    setDrawerType(DRAWER_TYPES.WP_OCCUPANCY);
    setDrawerLoading(true);

    const startDate = criterias.startDate
      ? moment(criterias.startDate)
      : moment().set({ hours: 0, minutes: 0 });
    const endDate = criterias.endDate
      ? moment(criterias.endDate)
      : moment().set({ hours: 23, minutes: 59 });

    const res = await PROXY.getWorkplacesOccupancy(siteId, roomDetails.reference, {
      page: 1,
      limit: 100,
      ...criterias,
      startDate: startDate.set({ seconds: 0, milliseconds: 0 }).toISOString(),
      endDate: endDate.set({ seconds: 59, milliseconds: 999 }).toISOString(),
    }).catch((error) => {
      if (error && error.code === "401") dispatch(logout());
    });

    setDrawerData(res);
    setDrawerLoading(false);
  };

  const handleUpdateCriterias = async (criterias) => {
    console.log(criterias);
    switch (drawerType) {
      case DRAWER_TYPES.CLMF:
        return handleHistoryClmf(criterias);
      case DRAWER_TYPES.COMMANDS:
        return handleCommandHistory(criterias);
      case DRAWER_TYPES.WEBHOOKS:
        return handleWebhookHistory(criterias);
      case DRAWER_TYPES.COUNT:
        return handleCountingHistory(criterias);
      case DRAWER_TYPES.COUNT_WEBHOOKS:
        return handleHistoryCountingHooks(criterias);
      case DRAWER_TYPES.WP_OCCUPANCY:
        return handleWPOccupancyHistory(criterias);
      case DRAWER_TYPES.WP_WEBHOOKS:
        return handleWPWebhookHistory(criterias);
      default:
        return null;
    }
  };

  const onUpdateSensors = (changed) => {
    dispatch(updateRoomSensors({ siteId, roomRef: currentRoom, sensors: changed })).then((res) => {
      if (res.error)
        notification.error({
          message: "Can't update room sensors",
          description: res.error.message,
        });
    });
  };
  const onUpdateWorkplace = (changed, all) => {
    console.log("onUpdateWorkplace", all);
    dispatch(
      updateWorkplace({
        siteId,
        roomRef: currentRoom,
        workplaceRef: all.reference,
        data: omit(["reference"], all),
      })
    ).then((res) => {
      if (res.error)
        notification.error({
          message: "Can't update workplace",
          description: res.error.message,
        });
    });
  };

  if (!isFetchingRooms && rooms && !rooms.length)
    return <Empty imageStyle={{ fontSize: "5em" }} image={<Icon component={NoDataImg} />} />;

  return (
    <div>
      <RoomHeader
        loading={isFetchingRooms || isFetchingDetails}
        roomDetails={roomDetails}
        onRefreshDetails={refreshDetails}
      />

      <Tabs defaultActiveKey="equipements" centered>
        <Tabs.TabPane tab="Equipements" key="equipements">
          <Equipements
            loading={isFetchingRooms || isFetchingDetails}
            roomDetails={roomDetails}
            onValuesChange={onUpdateSensors}
            onWebhooksHistory={handleWebhookHistory}
            onHistoryClmf={handleHistoryClmf}
            onHistoryCommand={handleCommandHistory}
            onHistoryCounting={handleCountingHistory}
            onHistoryCountingHooks={handleHistoryCountingHooks}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("workplaces")} key="workplaces">
          <Workplaces
            loading={isFetchingRooms || isFetchingWorkplaces}
            workplaces={workplaces}
            onUpdateWorkplace={onUpdateWorkplace}
            onHistoryOccupancy={handleWPOccupancyHistory}
            onWebhooksHistory={handleWPWebhookHistory}
          />
        </Tabs.TabPane>
      </Tabs>

      <HistoryDrawer
        loading={drawerLoading}
        visible={showHistoryDrawer}
        type={drawerType}
        data={drawerData}
        onClose={() => {
          setShowHistoryDrawer(false);
          setDrawerType(null);
          setCurrentCommandResType(null);
        }}
        onUpdateCriterias={handleUpdateCriterias}
      />
    </div>
  );
};

export default RoomDetails;
