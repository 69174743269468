export default {
  translation: {
    nav: {
      site: "Configuration",
      rooms: "Equipements",
    },
    login: "Connexion",
    logout: "Déconnexion",
    save: "Enregistrer",
    floor: "Étage",
    roomType: "Type de pièce",
    documentation: "Documentation API",
    loadPivot: "Charger un nouveau fichier Pivot",
    search: "Rechercher",
    filter: "Filtrer",
    apply: "Appliquer",
    timeRange: "Période",
    value: "Valeur",
    history: "Historique",
    webhooks: "Webhooks",
    commands: "Commandes",
    workplaces: "Postes de travail",
    webhooksHistory: "Historique des webhooks",
    commandsHistory: "Historique des commandes",
    occupancyHistory: "Historique d'occupation",
    hitorySize:
      "{{totalItems}} résultats sont disponibles. Les {{limit}} premiers ont été récupérés.",
    noEquipements: "Aucun capteur de niveau pièce n’est disponible",
    noWorkplaces: "Cette pièce n'a aucun poste de travail",
    equipements: {
      occupancy: "Occupation",
      fanSpeed: "Puissance de climatisation",
      fanOffset: "Température",
      fan: "Climatisation",
      light: "Eclairage",
      blind: "Volets",
      counting: "Fréquentation",
      co2: "CO2",
      humidity: "Humidité",
      temperature: "Température",
      brightness: "Luminosité",
      noise: "Bruit",
      cov: "Composé organique volatil (cov)",
      pm1: "Particules < 1μm",
      pm25: "Particules < 2.5μm",
      pm4: "Particules < 4μm",
      pm10: "Particules < 10μm"
    },
    helps: {
      login:
        "Si vous disposez d’un accès au simulateur ou au monitor, entrez votre email ci-dessous pour recevoir un mail de connexion",
      simulator:
        'Ce site est actuellement en <b>"mode simulateur"</b>. Les données des équipements sont fictives. Cet outil vous permet de consulter l’état actuellement simulé, de changer cet état et d’accéder à l’historique.',
      monitor:
        'Ce site est actuellement en <b>"mode production"</b>. Les données des équipements sont les données réelles. Cet outil vous permet de consulter l\'état des équipements et leur historique.',
      apikey: "Les informations suivantes vous permettent d'utiliser l'API du Proxy",
      search:
        "Vous pouvez rechercher une pièce à partir de son nom ou de sa référence (colonnes displayName et Reference du fichier pivot)",
      display_name: 'Colonne "DisplayName" Pivot',
      reference: 'Colonne "Reference" Pivot',
      floor: 'Colonne "Floor" Pivot',
      roomType: 'Colonne "Customer type of room" Pivot',
      bookable: "Réservable",
      history: {
        equipement: "Historique des changements d'état de l'équipement",
        webhook: "Historique des appels aux webhooks avec statut de réponse",
        command: "Historique des commandes envoyées aux équipements",
        workplaces: "Historique des changement d'occupation des workplaces",
        counting: "Historique du comptage de personnes dans la salle",
      },
      pivotForm:
        "Utilisez ce formulaire pour téléverser un nouveau fichier pivot et mettre à jour le référentiel de votre site. Cela peut prendre plus ou moins de temps dépendant de la taille de votre fichier",
    },
    forms: {
      noSite: "Choisissez un site pour commencer",
      pivotFormTitle: "Mise à jour du référentiel",
      email: "Adresse email",
      email_required: "Vous devez indiquer votre adresse mail",
      pivot_helper: "Cliquez ou glissez-déposez votre fichier pivot",
      pivot_helper_format: 'Fichier au format ".xlsx". ou ".xlsm"',
      pivot_required: "Vous devez indiquer un fichier pivot",
      randomMode_label: "Activer le mode Random",
    },
    loginSuccess: "Un mail contenant vos liens de connexion vous a été envoyé",
    loginFailure: "Echec de la connexion. Vérifiez vos identifiants",
    updateConfFailure: "Impossible de mettre à jour la configuration du site",
    updatePivotSuccess: "Le référentiel du site est à jour",
    updatePivotFailure: "Impossible de mettre à jour le référentiel du site",
  },
};
