import React from "react";
import moment from "moment";
import { cond, equals, groupBy, T, toPairs } from "ramda";
import { useTranslation } from "react-i18next";

import { Timeline, Typography, Empty } from "antd";

const TlItem = (ev) => {
  const { t } = useTranslation();

  return (
    <Timeline.Item
      color={cond([
        [equals("AVAILABLE"), () => undefined],
        [equals("OCCUPIED"), () => "red"],
        [T, () => "gray"],
      ])(ev.normalizedOccupancyStatus)}
    >
      <Typography.Text strong>{moment(ev.updatedAt).format("HH:mm:ss")}</Typography.Text>
      <p>
        {t("value")}: {ev.normalizedOccupancyStatus} ({ev.occupancyStatus})
      </p>
      {ev.normalizedOccupancyStatus === "ERROR" && ev.raw && (
        <pre>{typeof ev.raw === "object" ? JSON.stringify(ev.raw) : ev.raw}</pre>
      )}
    </Timeline.Item>
  );
};

const OccupancyHistory = (data) => {
  const { t } = useTranslation();

  if (!data || !data.items || !data.items.length) {
    return <Empty />;
  }

  const groupedData = groupBy((ev) => moment(ev.updatedAt).format("YYYY-MM-DD"), data.items);

  return (
    <>
      {data.hasNextPage && (
        <p>
          {t("hitorySize", {
            totalItems: data.totalItems,
            limit: data.limit,
          })}
        </p>
      )}
      {toPairs(groupedData).map(([date, events]) => {
        return (
          <>
            <Typography.Title level={3}>{moment(date).format("dddd DD MMM")}</Typography.Title>
            <br />
            <Timeline>
              {events.map((ev, idx) => (
                <TlItem key={idx} {...ev} />
              ))}
            </Timeline>
          </>
        );
      })}
    </>
  );
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <span>
      {t("history")}: <span className="txtPrimary">{t("equipements.occupancy")}</span>
    </span>
  );
};
const Help = () => {
  const { t } = useTranslation();
  return <p className="helpText">{t("helps.history.equipement")}</p>;
};

OccupancyHistory.Title = Title;
OccupancyHistory.Help = Help;
OccupancyHistory.searchable = true;
export default OccupancyHistory;
