import { Layout } from "antd";
import React from "react";
import Login from "../views/Auth/Login";
import styles from "./Layouts.module.less";

const AuthLayout = () => {
  return (
    <Layout className={styles.mainContainer}>
      <Layout.Content>
        <Login />
      </Layout.Content>
    </Layout>
  );
};

export default AuthLayout;
