import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Tag,
} from "antd";
import debounce from "lodash.debounce";
import React, { Children, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./Rooms.module.less";

const Section = ({ children }) => {
  const nbChildren = Children.toArray(children).length;
  return (
    <>
      <Row gutter={[16, 16]}>{children}</Row>
      {!!nbChildren && <Divider />}
    </>
  );
};

const Equipements = ({
  loading,
  roomDetails,
  onValuesChange,
  onWebhooksHistory,
  onHistoryClmf,
  onHistoryCommand,
  onHistoryCounting,
  onHistoryCountingHooks,
}) => {
  const { t } = useTranslation();

  const site = useSelector((state) => state.site.site);

  const debouncedChanged = useMemo(
    () => debounce(onValuesChange, 500),
    [onValuesChange]
  );

  const SHOW_LOADER = loading || !roomDetails;
  if (SHOW_LOADER)
    return (
      <>
        <br />
        <br />
        <br />
        <Spin />
      </>
    );

  if (
    roomDetails &&
    !roomDetails.occupancy_sensor &&
    !roomDetails.dom_fan &&
    !roomDetails.dom_light &&
    !roomDetails.dom_dimmable &&
    !roomDetails.dom_blind &&
    !roomDetails.dom_comfort &&
    !roomDetails.counting_sensor
  )
    return <Empty description={t("noEquipements")} />;

  return (
    <div>
      <div className={styles.wpActions}>
        {roomDetails &&
          (roomDetails.dom_fan ||
            roomDetails.dom_light ||
            roomDetails.dom_dimmable ||
            roomDetails.dom_blind) && (
            <Button
              key="commandsHistory"
              type="primary"
              onClick={() => onHistoryCommand()}
            >
              {t("commandsHistory")}
            </Button>
          )}
      </div>

      <Form
        onValuesChange={debouncedChanged}
        fields={[
          {
            name: ["occupancyStatus"],
            value: roomDetails.normalized_occupancy_status,
          },
          {
            name: ["domACFanSpeed"],
            value: roomDetails.ac_fan_speed,
          },
          {
            name: ["domACOffset"],
            value: roomDetails.ac_offset,
          },
          {
            name: ["currentCount"],
            value: roomDetails.current_count,
          },
          {
            name: ["domComfortCo2"],
            value: roomDetails.comfort_data?.co2,
          },
          {
            name: ["domComfortHumidity"],
            value: roomDetails.comfort_data?.humidity,
          },
          {
            name: ["domComfortTemperature"],
            value: roomDetails.comfort_data?.temperature,
          },
          {
            name: ["domComfortLight"],
            value: roomDetails.comfort_data?.brightness,
          },
          {
            name: ["domComfortNoise"],
            value: roomDetails.comfort_data?.noise,
          },
          {
            name: ["domComfortPressure"],
            value: roomDetails.comfort_data?.pressure,
          },
          {
            name: ["domComfortpm1"],
            value: roomDetails.comfort_data?.pm1,
          },
          {
            name: ["domComfortpm25"],
            value: roomDetails.comfort_data?.pm25,
          },
          {
            name: ["domComfortpm4"],
            value: roomDetails.comfort_data?.pm4,
          },
          {
            name: ["domComfortpm10"],
            value: roomDetails.comfort_data?.pm10,
          },
        ]}
      >
        <Section>
          {!!roomDetails.occupancy_type &&
            roomDetails.occupancy_type !== "NONE" && (
              <>
                <Col span={9}>
                  {t("equipements.occupancy")}{" "}
                  <Tag color="purple">{roomDetails.occupancy_type}</Tag>
                </Col>
                <Col span={9}>
                  <Form.Item noStyle name="occupancyStatus">
                    <Select
                      style={{ width: "100%" }}
                      disabled={!site || !site.isSim}
                    >
                      <Select.Option value="AVAILABLE">AVAILABLE</Select.Option>
                      <Select.Option value="OCCUPIED">OCCUPIED</Select.Option>
                      <Select.Option value="IN_PROGRESS">
                        IN_PROGRESS
                      </Select.Option>
                      <Select.Option value="ERROR">ERROR</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Space>
                    <Button block onClick={() => onWebhooksHistory()}>
                      {t("webhooks")}
                    </Button>
                    <Button block onClick={() => onHistoryClmf()}>
                      {t("history")}
                    </Button>
                  </Space>
                </Col>
              </>
            )}

          {!!roomDetails.counting_sensor && (
            <>
              <Col span={9}>
                {t("equipements.counting")}{" "}
                <Tag color="purple">{roomDetails.counting_type}</Tag>
              </Col>
              <Col span={9}>
                <Form.Item noStyle name="currentCount">
                  <InputNumber
                    style={{ width: "100%" }}
                    disabled={!site || !site.isSim}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Space>
                  <Button block onClick={() => onHistoryCountingHooks()}>
                    {t("webhooks")}
                  </Button>
                  <Button block onClick={() => onHistoryCounting()}>
                    {t("history")}
                  </Button>
                </Space>
              </Col>
            </>
          )}
        </Section>

        <Section>
          {!!roomDetails.dom_fan && (
            <>
              <Col span={9}>{t("equipements.fan")}</Col>
              <Col span={9}></Col>
              <Col span={6}>
                <Button
                  block
                  onClick={() => onHistoryCommand({ resourceType: "AC" })}
                >
                  {t("commandsHistory")}
                </Button>
              </Col>

              {site && site.isSim && (
                <>
                  <Col span={9}>{t("equipements.fanSpeed")}</Col>
                  <Col span={15}>
                    <Select
                      disabled
                      style={{ width: "100%" }}
                      value={roomDetails.ac_fan_speed}
                    >
                      <Select.Option value="V1">V1</Select.Option>
                      <Select.Option value="V2">V2</Select.Option>
                    </Select>
                  </Col>
                </>
              )}
              {site && site.isSim && (
                <>
                  <Col span={9}>{t("equipements.fanOffset")}</Col>
                  <Col span={15}>
                    <InputNumber
                      disabled
                      style={{ width: "100%" }}
                      value={roomDetails.ac_offset}
                    />
                  </Col>
                </>
              )}
            </>
          )}

          {(!!roomDetails.dom_light || !!roomDetails.dom_dimmable) && (
            <>
              <Col span={9}>{t("equipements.light")}</Col>
              <Col span={9}></Col>
              <Col span={6}>
                <Button
                  block
                  onClick={() => onHistoryCommand({ resourceType: "Lighting" })}
                >
                  {t("commandsHistory")}
                </Button>
              </Col>
            </>
          )}

          {!!roomDetails.dom_blind && (
            <>
              <Col span={9}>{t("equipements.blind")}</Col>
              <Col span={9}></Col>
              <Col span={6}>
                <Button
                  block
                  onClick={() =>
                    onHistoryCommand({ resourceType: "BlindsShutters" })
                  }
                >
                  {t("commandsHistory")}
                </Button>
              </Col>
            </>
          )}
        </Section>

        <Section>
          {!!roomDetails.dom_comfort?.co2 && (
            <>
              <Col span={9}>{t("equipements.co2")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortCo2"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.co2}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.humidity && (
            <>
              <Col span={9}>{t("equipements.humidity")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortHumidity"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.humidity}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.temperature && (
            <>
              <Col span={9}>{t("equipements.temperature")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortTemperature"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.temperature}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.brightness && (
            <>
              <Col span={9}>{t("equipements.light")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortLight"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.brightness}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.noise && (
            <>
              <Col span={9}>{t("equipements.noise")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortNoise"
                  normalize={(v) => v + ""}
                  defaultV
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.noise}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.pressure && (
            <>
              <Col span={9}>{t("equipements.pressure")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortPressure"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.pressure}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.pm1 && (
            <>
              <Col span={9}>{t("equipements.pm1")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortpm1"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.pm1}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.pm25 && (
            <>
              <Col span={9}>{t("equipements.pm25")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortpm25"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.pm25}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.pm4 && (
            <>
              <Col span={9}>{t("equipements.pm4")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortpm4"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.pm4}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.pm10 && (
            <>
              <Col span={9}>{t("equipements.pm10")}</Col>
              <Col span={9}>
                <Form.Item
                  noStyle
                  name="domComfortpm10"
                  normalize={(v) => v + ""}
                >
                  <InputNumber
                    disabled={!site?.isSim}
                    style={{ width: "100%" }}
                    value={roomDetails.comfort_data?.pm10}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </>
          )}
          {!!roomDetails.dom_comfort?.cov && (
            <>
              <Col span={9}>{t("equipements.cov")}</Col>
              <Col span={9}>
                <InputNumber
                  disabled
                  style={{ width: "100%" }}
                  value={roomDetails.comfort_data?.cov}
                />
              </Col>
              <Col span={6}></Col>
            </>
          )}
        </Section>
      </Form>
    </div>
  );
};

export default Equipements;
