import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, Descriptions, PageHeader, Button, Skeleton, Tooltip } from "antd";

import { ReloadOutlined } from "@ant-design/icons";

import styles from "./Rooms.module.less";

const RoomHeader = ({ loading, roomDetails, onRefreshDetails }) => {
  const { t } = useTranslation();
  const DISPLAY_SKELETON = loading || !roomDetails;

  return (
    <PageHeader
      className={styles.roomHeader}
      ghost={false}
      title={
        DISPLAY_SKELETON ? (
          <Skeleton active={loading} title={{ width: 100 }} paragraph={false} />
        ) : (
          <Tooltip title={t("helps.display_name")}>{roomDetails.display_name}</Tooltip>
        )
      }
      subTitle={
        DISPLAY_SKELETON ? null : (
          <Tooltip title={t("helps.reference")}>
            <Typography.Text copyable>{roomDetails.reference}</Typography.Text>
          </Tooltip>
        )
      }
      extra={[
        <Button
          key="refresh"
          disabled={DISPLAY_SKELETON}
          onClick={onRefreshDetails}
          type="primary"
          shape="circle"
          icon={<ReloadOutlined />}
        />,
      ]}
    >
      {DISPLAY_SKELETON ? (
        <Skeleton active={loading} title={false} paragraph={{ rows: 2 }} />
      ) : (
        <Descriptions size="small" column={3}>
          <Descriptions.Item label={t("roomType")}>
            <Tooltip title={t("helps.roomType")}>
              <Typography.Text>{roomDetails.customer_type_of_room}</Typography.Text>
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label={t("floor")}>
            <Tooltip title={t("helps.floor")}>
              <Typography.Text>{roomDetails.floor}</Typography.Text>
            </Tooltip>
          </Descriptions.Item>
        </Descriptions>
      )}
    </PageHeader>
  );
};

export default RoomHeader;
