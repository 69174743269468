import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./services/AuthSlice";
import siteReducer from "./services/SiteSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  site: siteReducer,
});

export default (state, action) => {
  if (action.error && action.error.code === "401")
    action = { type: "auth/logout" };
  if (action.type === "auth/logout") state = undefined;
  return rootReducer(state, action);
};
// export default rootReducer;
