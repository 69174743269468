import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";
import "moment/locale/fr";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import useAuth from "./providers/AuthProvider";
import "./styles/antd.less";
import "./styles/index.less";

const antLocales = {
  fr: frFR,
  en: enUS,
};

const App = () => {
  const { i18n } = useTranslation();
  const { ready, loggedIn, init } = useAuth();

  const lang = i18n.language.split("-")[0];
  useEffect(() => {
    console.log(`setting moment locale to ${lang}`);
    moment.locale(lang);
  }, [lang]);

  useEffect(() => {
    init();
  }, [init]);

  if (!ready) return null;
  return (
    <ConfigProvider locale={antLocales[lang]}>
      <Switch>{loggedIn ? <MainLayout /> : <AuthLayout />}</Switch>
    </ConfigProvider>
  );
};

export default App;
