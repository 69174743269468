import React from "react";
import moment from "moment";
import { groupBy, toPairs, cond, equals, always } from "ramda";
import { useTranslation } from "react-i18next";

import { Timeline, Typography, Empty } from "antd";

import {
  ExclamationCircleTwoTone,
  QuestionCircleTwoTone,
} from "@ant-design/icons";

const TlItem = (ev) => {
  const { t } = useTranslation();

  const itemProps = cond([
    [
      equals(-1),
      always({
        dot: <ExclamationCircleTwoTone twoToneColor="#eba836" />,
      }),
    ],
    [equals(0), always({})],
    [equals(1), always({ color: "red" })],
    [equals(2), always({ color: "red" })],
    [equals(3), always({ dot: <QuestionCircleTwoTone /> })],
  ])(ev.occupancyStatus);

  return (
    <Timeline.Item {...itemProps}>
      <Typography.Text strong>
        {moment(ev.updatedAt).format("HH:mm:ss")}
      </Typography.Text>
      <p>{ev.reference}</p>
      <p>
        {t("value")}: {ev.normalizedOccupancyStatus} ({ev.occupancyStatus})
      </p>
    </Timeline.Item>
  );
};

const WPOccupancyHistory = (data) => {
  const { t } = useTranslation();

  if (!data || !data.items || !data.items.length) {
    return <Empty />;
  }

  const groupedData = groupBy(
    (ev) => moment(ev.updatedAt).format("YYYY-MM-DD"),
    data.items
  );

  return (
    <>
      {data.hasNextPage && (
        <p>
          {t("hitorySize", {
            totalItems: data.totalItems,
            limit: data.limit,
          })}
        </p>
      )}
      {toPairs(groupedData).map(([date, events]) => {
        return (
          <>
            <Typography.Title level={3}>
              {moment(date).format("dddd DD MMM")}
            </Typography.Title>
            <br />
            <Timeline>
              {events.map((ev, idx) => (
                <TlItem key={idx} {...ev} />
              ))}
            </Timeline>
          </>
        );
      })}
    </>
  );
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <span>
      {t("history")}:{" "}
      <span className="txtPrimary">{t("equipements.occupancy")}</span>
    </span>
  );
};
const Help = () => {
  const { t } = useTranslation();
  return <p className="helpText">{t("helps.history.workplaces")}</p>;
};

WPOccupancyHistory.Title = Title;
WPOccupancyHistory.Help = Help;
WPOccupancyHistory.searchable = true;
export default WPOccupancyHistory;
