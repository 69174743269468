import { Button, Card, Empty, Form, Input, Select, Spin, Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectedSite } from "../../../redux/services/SiteSlice";
import styles from "./Rooms.module.less";

const Workplaces = ({
  loading,
  workplaces,
  onUpdateWorkplace,
  onHistoryOccupancy,
  onWebhooksHistory,
}) => {
  const { t } = useTranslation();
  const site = useSelector(selectedSite);

  const SHOW_LOADER = loading || !workplaces;

  if (SHOW_LOADER)
    return (
      <>
        <br />
        <br />
        <br />
        <Spin />
      </>
    );

  if (workplaces && !workplaces.length) return <Empty description={t("noWorkplaces")} />;

  return (
    <div>
      <div className={styles.wpActions}>
        <Button type="primary" onClick={() => onWebhooksHistory()}>
          {t("webhooksHistory")}
        </Button>
        <Button type="primary" onClick={() => onHistoryOccupancy()}>
          {t("occupancyHistory")}
        </Button>
      </div>

      <div className={styles.workplaces}>
        {workplaces.map((wp) => (
          <Card
            key={wp.reference}
            title={wp.reference}
            extra={<Tag color="purple">{wp.occupancy_type}</Tag>}
          >
            <Form onValuesChange={onUpdateWorkplace}>
              <Form.Item noStyle name="reference" initialValue={wp.reference}>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item noStyle name="occupancyStatus" initialValue={wp.normalizedOccupancyStatus}>
                <Select style={{ width: "100%" }} disabled={!site || !site.isSim}>
                  <Select.Option value="AVAILABLE">AVAILABLE</Select.Option>
                  <Select.Option value="OCCUPIED">OCCUPIED</Select.Option>
                  <Select.Option value="IN_PROGRESS">IN_PROGRESS</Select.Option>
                  <Select.Option value="ERROR">ERROR</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Workplaces;
