import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import keycloak from "../keycloak";
import { setToken } from "../redux/services/AuthSlice";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [ready, setReady] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    keycloak.onAuthSuccess = async () => {
      const userInfo = await keycloak.loadUserInfo();
      setUserInfo(userInfo);
      setLoggedIn(true);
      dispatch(setToken({ token: keycloak.token }));
    };

    keycloak.onAuthRefreshSuccess = async () => {
      const userInfo = await keycloak.loadUserInfo();
      setUserInfo(userInfo);
    };

    keycloak.onAuthRefreshError = async () => {
      setLoggedIn(false);
    };

    keycloak.onAuthError = () => {
      setLoggedIn(false);
    };

    keycloak.onReady = () => {
      setReady(true);
      setLoggedIn(keycloak.authenticated);
    };

    keycloak.onAuthLogout = () => {
      setLoggedIn(false);
    };

    keycloak.onTokenExpired = async () => {
      keycloak.updateToken(5).catch(() => {
        history.push("/auth");
      });
    };
  }, [dispatch, history]);

  const init = useCallback(async () => {
    await keycloak.init({
      onLoad: "check-sso",
      flow: "standard",
      checkLoginIframe: false,
    });
  }, []);

  return (
    <AuthContext.Provider value={{ ready, loggedIn, userInfo, init }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
